import axios, { AxiosRequestConfig } from 'axios'
import Vue from '@/main'
import message from 'ant-design-vue/es/message'
import store from "@/store"

let api = ''
const isPro = process.env.NODE_ENV === 'production'

if (!isPro) {
  // api = '/api/service-dev/'
  api = '/api'
} else {
  // api = '/singapore-cloud-service-test/'
  api = process.env.VUE_APP_BASE_URL
}
console.log(isPro, '。。。。。。。。。。。。。。')
// api = isPro ? '' : '/api'

export class Caxios {
  // 通用选项
  private static commonOptions: any = {
    responseType: 'json',
    timeout: 3000000
  };
  // 设置headers
  private static setHeaders(options: any = {}) {
    const branchSchoolId = localStorage.getItem('singapore_branchSchoolId')
    if (!options['headers']) {
      options['headers'] = {};
    }
    let userInfo: any = localStorage.getItem('singapore_personal_Data')
    userInfo = JSON.parse(userInfo)
    // console.log('branchSchoolId!== \'null\'',branchSchoolId,branchSchoolId === 'null',JSON.parse(userInfo))
    options['headers']['app-Key'] = process.env.VUE_APP_KEY;
    options['headers']['endApp-type'] = 'web';
    options['headers']['user-type'] = 'S';
    options['headers']['language'] = store.state.common.lang === 'zh-CN' ? 'zh' : 'en';
    options['headers']['branchSchoolCode'] = branchSchoolId && branchSchoolId !== 'null' ? branchSchoolId : 'sgfx';
    if (userInfo?.studentId) {
      options['headers']['student-user-id'] = userInfo.studentId
    }
    // 设置默认请求头内容类型
    if (options.method === 'POST') {
      if (!options['headers']['Content-Type']) {
        options['headers']['Content-Type'] = 'application/json; charset=UTF-8';
      }
    }
    // 添加TOKEN
    const TOKEN = localStorage.getItem('singapore_token')
    if (TOKEN) {
      options['headers']['Authorization'] = `Bearer ${TOKEN}`;
    }
    return options;
  }
  public static async invoke<T>(
    options: AxiosRequestConfig
  ): Promise<any> {
    if (!options) return Promise.reject('axios请求参数配置不可以为空')

    options = Caxios.setHeaders(options)

    const method = options.method || 'GET',
      instance = axios.create(Caxios.commonOptions);

    // 请求拦截器
    instance.interceptors.request.use(
      (request: any): any => {
        if (method === 'POST') {
          request.data = JSON.stringify(request.data);
        }
        request.url = request.url.replace('/api', api)
        return request;
      },
      (err: any): any => {
        return Promise.reject(err);
      }
    );

    // 响应拦截器
    instance.interceptors.response.use(
      (response: any): any => {
        return response;
      },
      (err: any): any => {
        let result = err;
        // 取消处理
        if (err instanceof axios.Cancel) {
          result = ''; // 返回空时，Prompt不提示
        }
        // 网络异常处理
        const errMsg = err.message || '';
        if (errMsg.indexOf('Network Error') > -1) {
          message.warning('系统繁忙，请稍后重试');
        } else if (errMsg.indexOf('timeout of') > -1) {
          message.warning('系统繁忙，请稍后重试');
        }
        return Promise.reject(result);
      }
    );

    const response = await instance.request(options)
    console.log('response', response)
    if (response.data.code === '11003' || response.data.code === '11002') {
      console.log('store', store)
      store.commit('common/setCommonComponentName', 'MineLogin')
    } else if (response.data.code === '11110') {
      let timeNum = 0
      timeNum += 1
      if (timeNum >= 3) return false
      Caxios.post({ url: '/api/official/student/get/students' }).then((su: any) => {
        if (su.code === '10000') {
          // 通知全局刷新
          if (su.data) {
            localStorage.setItem('singapore_branchSchoolId', String(su.data.branchSchoolCode))
            localStorage.setItem('singapore_personal_Data', JSON.stringify(su.data));
          } else {
            localStorage.removeItem('singapore_personal_Data');
          }
          // window.location.reload()
          const vue: any = Vue
          vue.$eventBus.$emit('updataApp', su.data ? 'Y' : 'N')
          // 10秒后没有再次触发 重置为0
          setTimeout(() => {
            timeNum = 0
          }, 10000)
        }
      })
      return false
    } else if (response.data.code !== '10000' && (response.data.code !== '11003' && response.data.code !== '11002')) {
      message.warning(response.data.msg ? response.data.msg : '系统繁忙')
    }
    return response.data
  }
  // GET方法请求
  public static async get<T>(
    options: AxiosRequestConfig
  ): Promise<T> {
    if (!options) return Promise.reject('axios配置参数不可以为空');
    options['method'] = 'GET';
    return await Caxios.invoke<T>(options);
  }

  // GET方法请求
  public static async delete<T>(
    options: AxiosRequestConfig
  ): Promise<T> {
    if (!options) return Promise.reject('axios配置参数不可以为空');
    options['method'] = 'DELETE';
    return await Caxios.invoke<T>(options);
  }

  // POST方法请求
  public static async post<T>(
    options: AxiosRequestConfig
  ): Promise<T> {
    if (!options) return Promise.reject('axios配置参数不可以为空');
    options['method'] = 'POST';
    return await Caxios.invoke<T>(options);
  }
}
