
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { mapGetters, mapMutations, mapActions } from 'vuex'
  import storage from 'store'
  const _ = require('lodash')


  import { Caxios } from './../../../utils/axios'

  import { qqGroupItem, navItem } from './../../../store/interface'
  import message from "*";

@Component({
  name: 'headerView',
  computed: {
    ...mapGetters('common', ['clientWidth', 'mainWidth', 'cityPosition', 'qqGroupList', 'navList', 'login']),
    ...mapGetters('personal', ['personalData']),
    ...mapGetters('course', ['grade']),
    curNavList () {
      const nav: any = [
        {
          text: this.$t('common.nav.shouye'),
          url: '/dashboard/index',
          iconFlag: false,
          componentName: ''
        },
        {
          text: this.$t('common.nav.xuankezhongxin'),
          url: '/course/index',
          iconFlag: false,
          componentName: ''
        },
        {
          text: this.$t('common.nav.jiaoliuzhongxin'),
          url: 'http://bbs.kangaroo.study',
          iconFlag: false,
          componentName: ''
        },
        {
          text: '隐私政策',
          url: '',
          iconFlag: false,
          componentName: 'PrivacyPolicy'
        },
        {
          text: this.$t('common.nav.guanyuwomen'),
          url: '/aboutUs/index',
          iconFlag: false,
          componentName: ''
        },
        {
          text: this.$t('common.nav.kehuduanxiazai'),
          url: '',
          iconFlag: true,
          componentName: ''
        }/*,
        {
          text: '校长信箱',
          url: '',
          iconFlag: false,
          componentName: 'HeadmasterMailbox'
        }*/,
        {
          text: '加入我们',
          url: 'https://job.fangtian.me/',
          iconFlag: false,
          componentName: ''
        }
      ]
      return nav.filter((itm: any): boolean => { return !itm.componentName && itm.text !== '加入我们' })
    }
  },
  methods: {
    ...mapMutations('common', ['setCommonComponentName', 'setLang', 'setLogin']),
    ...mapActions('personal', ['setPersonalData', 'editbase'])
  }
})
export default class headerView extends Vue {
  clientWidth!: number
  mainWidth!: number
  editbase!: any
  cityPosition!: any
  grade!: Array<any>
  qqGroupList!: Array<qqGroupItem>
  navList!: Array<any>
  personalData!: any
  setCommonComponentName!: any
  setPersonalData!: any
  curNavList!: any
  defaultLang!: any
  setLang!: any
  login!: any
  setLogin!: any

  beforeMount() {
    const lang = storage.get('lang')
    this.defaultLang = lang ? lang : 'zh-CN'
    this.setLang(this.defaultLang)
  }

  private qqGroupFlag = false
  private leftPos = '50%'
  private curQqGroupList: Array<qqGroupItem> = []
  private curCityPosition: any = {}
  private studentOptions: any = []
  private studentCurrent: any = ''
  private activeIndex = 0
  private loginFlag = false
  private visible = false
  public personInfo: any = localStorage.getItem('singapore_personal_Data')
  public token: any = localStorage.getItem('singapore_token')|| ''

  private gradeInfo: any = {
    text: '全部年级',
    id: ''
  }
  private languageInfo: any = {
    text: '中文',
    id: 'zh'
  }
  private languageList = [
    {
      label: 'English',
      value: 'en-US'
    },
    {
      label: '中文',
      value: 'zh-CN'
    }
  ]
  private curGradeList: Array<any> = []
  private gradeInfoFlag = false
  private languageFlag = false
  private qqMouseEvent (): void {
    this.qqGroupFlag = !this.qqGroupFlag
  }
  // @Watch('appNav', { immediate: true, deep: true })
  // navListWatch(newVal: Array<any>): void {
  //     if(newVal && Array.isArray(newVal) && newVal.length > 0) {
  //         // this.curNavList = newVal.filter((itm: any): boolean => { return !itm.componentName && itm.text !== '加入我们' })
  //         this.setNavList(newVal)
  //     }
  // }

  // 处理QQ群的位置
  @Watch('clientWidth', { immediate: true, deep: true })
  clientWidthWatch(newVal: number): void {
    let _right = 0// 宽度是1200的时候距离右端的距离
    if(newVal >= 1680) {
      this.leftPos = '-239px'
    } else if(newVal <= 1200) {
      this.leftPos = '-456px'
    } else {
      _right = (newVal - this.mainWidth) / 2
      this.leftPos = `-${480 - _right}px`
    }
  }
  /*@Watch('cityPosition', { immediate: true, deep: true })
  cityPositionWatch(newVal: any): void {
    this.curCityPosition = newVal
  }*/

  @Watch('cityPosition', { immediate: true, deep: true })
  cityPositionWatch(newVal: any): void {
    this.curCityPosition = newVal
  }
  @Watch('qqGroupList', { immediate: true, deep: true })
  qqGroupListWatch(newVal: any): void {
    this.curQqGroupList = newVal
  }
  @Watch('token', { immediate: true, deep: true })
  tokenWatch(newVal: any): void {
    console.log('tokenWatch',newVal)
  }
  @Watch('login', { immediate: true, deep: true })
  loginWatch(newVal: any): void {
    console.log('loginWatch',newVal)
    this.token = localStorage.getItem('singapore_token')|| ''
    this.personInfo = localStorage.getItem('singapore_personal_Data')
    this.initApp();
  }

  @Watch('$route', { immediate: true, deep: true })
  routeWatch(newVal: any): void {
    this.activeIndex = -1
    this.curNavList.forEach((v: any, i: number): void => {
      if(v.url == newVal.path) {
        this.activeIndex = i
      }
    })
    if(newVal.path == '/course/detail') {
      this.activeIndex = 1
    }
    if(newVal.path == '/personal/index') {
      this.activeIndex = this.curNavList.length
    }
    if(newVal.path == '/nearbyCampus/index') {
      this.activeIndex = 0
    }
  }
  @Watch('personalData', { immediate: true, deep: true })
  personalDataWatch(newVal: any): void {
    if(newVal && newVal) {
      this.loginFlag = true
    } else {
      this.loginFlag = false
    }
  }

  @Watch('grade', { immediate: true, deep: true })
  gradeWatch(newVal: Array<any>): void {
    if(newVal && Array.isArray(newVal) && newVal.length > 0) {
      this.curGradeList = [ ...newVal ]
      // this.curGradeList.map((item: any): void => {
      //     if(item.text == '不限') {
      //         item.text = '全部年级'
      //     }
      // })
      if(this.personalData?.studentId && this.personalData?.grade) {
        this.curGradeList.forEach((item: any): void => {
          if(item.id == this.personalData.grade) {
            this.gradeInfo = { ...item }
          }
        })
      }
    }
  }
  private initApp () {
    console.log('token',this.token, this.personInfo)
    if (this.personInfo) {
      this.personInfo = JSON.parse(this.personInfo)
    } else {
      this.personInfo = {}
    }
    if(this.personInfo?.studentId){
      this.getStudentList()
    }
    if (this.token) {
      this.getStudents()
    }
  }
  created() {
    // this.initApp();

    (this as any).$eventBus.$on('updataApp', (flag: any) => {
      const _this: any = this
      let userData = localStorage.getItem('singapore_personal_Data')
      if (userData) {
        userData = JSON.parse(userData)
      }
      _this.personInfo = userData || {};
      if (flag === 'Y' && Object.keys(_this.personInfo).length > 0) {
        this.getStudentList()
        this.setPersonalData(_this.personInfo)
      } else {
        this.setPersonalData({})
      }
    });
  }
  private async getStudents () {
    const _result: any = await Caxios.post({ url: '/api/official/student/get/students'})
    if(_result.code === '10000' && _result.data) {
      localStorage.setItem('singapore_branchSchoolId', String(_result.data.branchSchoolCode))
      localStorage.setItem('singapore_personal_Data', JSON.stringify(_result.data));
      this.personInfo = _result.data
      this.setPersonalData(_result.data)
    }
  }
  // private setNavList(list: any = this.appNav): void {
  //     console.log('list', list)
  //     this.curNavList = list.filter((itm: any): boolean => { return !itm.componentName && itm.text !== '加入我们' })
  // }
  private onClick({key}: any): void{
    console.log(key)
    if (key === '1') {
      this.studentCurrent = ''
      this.getStudentList()
      this.visible = true
    } else if (key === '2') {
      this.personalEvent('Personal')
    } else {
      this.logoutEvent()
    }
  }
  /*切换学员*/
  private async handleOk(){
    const _result: any = await Caxios.post({ url: '/api/official/student/true/change',data:{currentStudentId:this.studentCurrent}})
    if(_result.code === '10000') {
      localStorage.setItem('singapore_personal_Data', JSON.stringify(_result.data));
      this.personInfo = _result.data
      // this.editbase(_.cloneDeep(this.personInfo))
      this.visible = false
      this.$message.success('切换成功')
      this.$router.go(0)
    }
  }
  /*切换学员*/
  private handleCancel(): void{
    this.visible = false
    this.studentCurrent = ''
  }

  private async getStudentList () {
    const _result: any = await Caxios.post({ url: '/api/official/student/change/student',
      data:{
        familyType: this.personInfo.familyType,
        currentStudentId: this.personInfo?.studentId
      } })
    if (_result.code === '10000') {
      this.studentOptions = !_result.data.otherStudents ? [] : _result.data.otherStudents.map((item: any) =>{
        return {
          value: item.studentId,
          label: item.name
        }
      })
      console.log('studentOptions',this.studentOptions)
    }
  }
  // 点击logo前往 首页
  private toHome(): void {
    console.log('去首页')
    this.$router.push({ path: '/' })
  }
  private showDialogEvent (copmName: string): void {
    this.setCommonComponentName(copmName)
  }
  private joinEvent (): void {
    window.open('https://job.fangtian.me/')
  }
  private personalEvent (copmName: string): void {
    // this.setCommonComponentName(copmName)
    this.activeIndex = this.curNavList.length
    const _this: any = this
    _this.$router.push('/personal')
  }
  private async logoutEvent () {
    // 调用退出登录接口
    const _result: any = await Caxios.post({ url: '/api/official/user/logout' })
    if(_result.code === '10000'){
      Object.keys(localStorage).forEach((attr: string): void => {
        if((attr != 'fangtian_user' && attr != 'fangtian_pwd' && attr != 'lang')) {
          localStorage.removeItem(attr)
        }
      })
      this.setPersonalData({})
      this.personInfo = {}
      this.token = ''
      const _this: any = this
      _this.$router.push('/dashboard/index')
      this.setLogin(false)
    }
  }
  private changeGrade (item: any): void {
    this.gradeInfo = { ...item }
    this.gradeInfoFlag = false
  }
  private changeLanguage (item: any): void {
    //   const url = this.$route.path
    //   const params = this.$route.query
    //   const qs = require('qs')
    //   const rl = (Object.keys(params).length > 0 ) ? (url+ '?' +qs.stringify(params)):url
    // if (item === 'en') {
    //   this.languageFlag = false
    //   window.open(`https://kangaroo.study${rl}`, '_parent', 'location=yes')
    //   // window.open(`https://pre.fangtian.me${rl}`, '_parent', 'location=yes')
    //
    // }
    //   console.log('item', item)
    //   loadLanguageAsync(item)
    (document.querySelector('html') as Element).setAttribute('lang', item)
    import(/* webpackChunkName: "lang-[request]" */ `@/locales/lang/${item}`).then(msg => {
      const locale = msg.default
      this.$i18n.setLocaleMessage(item, locale)
      this.$i18n.locale = item
      this.setLang(item)
      storage.set('lang', item)
    })

    console.log(this.$i18n.locale)
  }

  private openUrl (item: navItem, index: number): void {
    if(item.url.indexOf('http://') >= 0 || item.url.indexOf('https://') >= 0) {
      window.open(item.url)
    } else {
      this.activeIndex = index
      const _this: any = this
      _this.$router.push(item.url)
    }
  }
}
