export default {
    'common.nav.shouye': '首页',
    'common.nav.xuankezhongxin': '选课中心',
    'common.nav.jiaoliuzhongxin': '交流中心',
    'common.nav.guanyuwomen': '关于我们',
    'common.nav.kehuduanxiazai': '客户端下载',
    'common.nav.gerenzhongxin': '个人中心',
    'common.nav.tuichu': '退出',
    'common.nav.downloadApp': '扫码下载 App',
    'common.nav.denglu': '登录',
    'common.nav.zhuce': '注册',
    'common.nav.xiaozhangxinxiang': '校长信箱',
    'common.nav.jiaruwomen': '加入我们',
    'common.nav.yinsizhengce': '隐私政策',
    'common.nav.wangjimima': '忘记密码',
    'common.nav.xuanzechengshi': '请选择所在城市',
    'common.t1': '请输入用户名',
    'common.t2': '请输入密码',
    'common.t3': '记住密码',
    'common.t4': '没有账号',
    'common.t5': '立即注册',
    'common.t6': '手机号格式不正确',
    'common.t7': '密码不能为空',
    'common.t8': '登陆成功',
    'common.t9': '请选择分校',
    'common.t10': '手机号码',
    'common.t11': '验证码',
    'common.t12': '新密码8-12位',
    'common.t13': '姓名',
    'common.t14': '男',
    'common.t15': '女',
    'common.t16': '请选择年级',
    'common.t17': '用户注册协议',
    'common.t18': '我同意',
    'common.t19': '姓名不能为空',
    'common.t20': '密码长度不对',
    'common.t21': '密码不能为空',
    'common.t22': '验证码不能为空',
    'common.t23': '年级不能为空',
    'common.t24': '手机号码不能为空',
    'common.t25': '请先阅读用户协议后确认同意，再进行注册',
    'common.t26': '注册成功',
    'common.t27': '新密码8-12位',
    'common.t28': '确认密码',
    'common.t29': '提交',
    'common.t30': '发送验证码',
    'common.t31': '重新发送',
    'common.t32': '两次密码输入不一致',
    'common.t33': '密码修改成功',
    'common.t34': '请选择',
    'common.t35': '录入父亲职业信息',
    'common.t36': '请选择科目',
    'common.t37': '数学（明辨）',
    'common.t38': '华文',
    'common.t39': '请选择业务类型',
}
